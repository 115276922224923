import { amber, grey } from '@material-ui/core/colors'
import { darken, lighten } from '@material-ui/core/styles'

export const DEFAULT_TONAL_OFFSET = 0.2

// Colors palette and names come from: https://coolors.co/
export const COLORS = {
    white: '#FFFFFF',
    black: '#000000',
    theme: {
        ajat_green: {
            main: '#00dbb4',
            light: lighten('#00dbb4', DEFAULT_TONAL_OFFSET),
            dark: darken('#00dbb4', DEFAULT_TONAL_OFFSET)
        },
        proud_nerds_black: {
            main: '#1a1919',
            light: '#212531'
        },
        proud_nerds_gold: {
            main: '#97855c',
            light: '#a99567'
        },
        proud_nerds_grey: {
            light: '#ececeb',
            dark: '#d8d8d8'
        },
        dark_purple: {
            main: '#2B193D',
            light: lighten('#2B193D', DEFAULT_TONAL_OFFSET),
            dark: darken('#2B193D', DEFAULT_TONAL_OFFSET)
        },
        space_cadet: {
            main: '#2C365E',
            light: lighten('#2C365E', DEFAULT_TONAL_OFFSET),
            dark: darken('#2C365E', DEFAULT_TONAL_OFFSET)
        },
        independence: {
            main: '#484D6D',
            light: lighten('#484D6D', DEFAULT_TONAL_OFFSET),
            dark: darken('#484D6D', DEFAULT_TONAL_OFFSET)
        },
        onyx: {
            main: '#424242',
            light: lighten('#424242', DEFAULT_TONAL_OFFSET),
            dark: darken('#424242', DEFAULT_TONAL_OFFSET)
        },
        error_red: {
            main: '#f44336',
            light: lighten('#f44336', DEFAULT_TONAL_OFFSET),
            dark: darken('#f44336', DEFAULT_TONAL_OFFSET)
        },
        warning_yellow: {
            main: '#E5AA2A',
            light: lighten('#E5AA2A', DEFAULT_TONAL_OFFSET),
            dark: darken('#E5AA2A', DEFAULT_TONAL_OFFSET)
        },
        success_green: {
            main: '#4caf50',
            light: lighten('#4caf50', DEFAULT_TONAL_OFFSET),
            dark: darken('#4caf50', DEFAULT_TONAL_OFFSET)
        },
        amber: {
            main: amber[500],
            light: amber[200],
            dark: amber[700]
        },
        grey: {
            main: grey[500],
            light: grey[300],
            dark: grey[800]
        }
    }
}
