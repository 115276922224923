import StorefrontIcon from '@mui/icons-material/Storefront'
import RetailerEdit from './RetailerEdit'
import RetailerList from './RetailerList'
import RetailerCreate from './RetailerCreate'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    list: RetailerList,
    edit: RetailerEdit,
    create: RetailerCreate,
    icon: StorefrontIcon
}
