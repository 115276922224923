import { useState } from 'react'
import { Create, useTranslate } from 'react-admin'

import { BackFromEditorButton } from 'components/common/BackFromEditorButton'

import RetailerForm from './RetailerForm'

const RetailerCreate = (props) => {
    const translate = useTranslate()
    const [isPristineForm, setIsPristineForm] = useState(true)

    return (
        <>
            <BackFromEditorButton isPristineForm={isPristineForm} backToRouteURL="/retailer" />
            <Create title={translate(`manager.resources.${props.resource}.create`)} {...props}>
                <RetailerForm
                    isPristineForm={isPristineForm}
                    setIsPristineFormCallback={setIsPristineForm}
                    {...props}
                />
            </Create>
        </>
    )
}

export default RetailerCreate
