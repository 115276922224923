import { useTranslate } from 'ra-core'
import { useEffect, useState } from 'react'

import { ChoiceType } from 'types'
import TagsAutocompleteInput from 'components/formfields/TagsAutocompleteInput'
import { useCreateRetailerTagMutation } from 'apollo/retailer_manager/mutations/CreateRetailerTag.generated'
import { useRetailersRetailerQuery } from 'apollo/retailer_manager/queries/RetailersRetailer.generated'
import { useRetailersRetailerTagsQuery } from 'apollo/retailer_manager/queries/RetailersRetailerTags.generated'

/*
 * Wrapper component to provide the actual autocomplete input with data and functionality \
 */
const RetailerTagsInput = ({ retailerId }) => {
    const translate = useTranslate()
    const [initialValues, setInitialValues] = useState<ChoiceType[]>([])
    const [initialChoices, setInitialChoices] = useState<ChoiceType[]>([])
    const [createRetailerTag] = useCreateRetailerTagMutation()

    // Retreive all tags related to this retailerId
    const { data: retailerTags } = useRetailersRetailerQuery({
        variables: {
            retailerId: retailerId
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-and-network'
    })

    // Retrieve every possible tag to populate choices in the autocomplete
    const { data: { retailersRetailerTags } = {} } = useRetailersRetailerTagsQuery({
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-and-network'
    })

    // Format and set choices
    useEffect(() => {
        if (retailersRetailerTags) {
            setInitialChoices(
                retailersRetailerTags.data.map(
                    (item): ChoiceType => ({
                        id: item.id,
                        name: item.tag
                    })
                )
            )
        }
    }, [retailersRetailerTags])

    // Format and set values
    useEffect(() => {
        if (retailerTags?.retailersRetailer?.tags) {
            setInitialValues(
                retailerTags.retailersRetailer.tags.map(
                    (item): ChoiceType => ({
                        id: item.id,
                        name: item.tag
                    })
                )
            )
        }
    }, [retailerTags])

    // Prompt user for new tag
    const handleCreate = async () => {
        const newTag = prompt(translate('manager.resources.retailer.enter_new_tag'))
        if (!newTag) return // Early out

        const found = initialChoices.find((item) => item.name === newTag)
        if (found) return found // Return existing tag

        // Save and return new tag
        const tag = await save(newTag)
        initialChoices && tag && initialChoices.push(tag)
        return tag
    }

    // Save new tag in db
    const save = async (newTag: string) => {
        return await createRetailerTag({
            variables: { tag: newTag }
        })
            .then((res) => {
                return {
                    id: res?.data?.createRetailerTag?.id ?? '',
                    name: res?.data?.createRetailerTag?.tag ?? ''
                }
            })
            .catch((error) => console.error('createRetailerTag:', error))
    }

    return (
        <TagsAutocompleteInput
            source="tags"
            initialValues={initialValues}
            initialChoices={initialChoices}
            onCreate={handleCreate}
        />
    )
}
export default RetailerTagsInput
