import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type RetailersRetailerQueryVariables = Types.Exact<{
  retailerId?: Types.InputMaybe<Types.Scalars['ID']>;
}>;


export type RetailersRetailerQuery = { __typename?: 'Query', retailersRetailer?: { __typename?: 'retailersRetailer', id: string, sales_responsible?: number | null | undefined, sales_team?: number | null | undefined, tags: Array<{ __typename?: 'retailersRetailerTag', tag: string, id: string }> } | null | undefined };


export const RetailersRetailerDocument = gql`
    query RetailersRetailer($retailerId: ID) {
  retailersRetailer(id: $retailerId) {
    id
    sales_responsible
    sales_team
    tags {
      tag
      id
    }
  }
}
    `;

/**
 * __useRetailersRetailerQuery__
 *
 * To run a query within a React component, call `useRetailersRetailerQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetailersRetailerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetailersRetailerQuery({
 *   variables: {
 *      retailerId: // value for 'retailerId'
 *   },
 * });
 */
export function useRetailersRetailerQuery(baseOptions?: Apollo.QueryHookOptions<RetailersRetailerQuery, RetailersRetailerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RetailersRetailerQuery, RetailersRetailerQueryVariables>(RetailersRetailerDocument, options);
      }
export function useRetailersRetailerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RetailersRetailerQuery, RetailersRetailerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RetailersRetailerQuery, RetailersRetailerQueryVariables>(RetailersRetailerDocument, options);
        }
export type RetailersRetailerQueryHookResult = ReturnType<typeof useRetailersRetailerQuery>;
export type RetailersRetailerLazyQueryHookResult = ReturnType<typeof useRetailersRetailerLazyQuery>;
export type RetailersRetailerQueryResult = Apollo.QueryResult<RetailersRetailerQuery, RetailersRetailerQueryVariables>;