/**
 * Declare here query overrides:
 * a query name and set of fields
 */

const overridesMap = {
    configuratorPreset: `
      active
      id
      internal_name
      model {
        id
        code
      }
      parent_model_id
      public_name
      published
      published_code
    `,
    configuratorPresets: `
      active
      id
      internal_name
      public_name
      updated_at
      parent_model_id
    `,
    packagesPackageGroupCollections: `
      internal_name
      public_name
      active
      updated_at
    `,
    packagesPackageGroups: `
      internal_name
      public_name
      active
      updated_at
      presets {
        code
      }
    `,
    packagesPackages: `
      public_name
      order
      type
      active
      updated_at
      definition
    `,
    retailersCreateRetailer: `
      active
      address
      code
      city
      classes {
        active
        name
        updated_at
        year {
          name
        }
      }
      country
      embroidery_1
      embroidery_2
      embroidery_3
      extension_attributes
      hero_banner
      hero_banner_url
      internal_note
      logo
      logo_url
      logo_2
      logo_2_url
      logo_3
      logo_3_url
      logo_4
      logo_4_url
      marketing_url
      name
      priority
      periods {
        active
        from_date
        grace_payment_date
        to_date
        updated_at
      }
      personalization_logo
      personalization_logo_url
      post_code
      priority
      sales_responsible
      sales_team
      representative_email
      representative_name
      representative_ssn
      representative_telephone
      representatives {
        classes {
          id
          name
        }
        discount_amount
        discount_percentage
        email
        id
        name
        retailers {
          code
          name
        }
        ssn
        telephone
        updated_at
      }
      students_amount
      tags {
        id
        tag
      }
    `,
    retailersCreateRetailerClass: `
      active
      code
      embroidery_1
      embroidery_2
      embroidery_3
      extension_attributes
      id
      name
      priority
      retailer_id
      retailer_year_id
      students_amount
      representatives {
        classes {
          id
          name
        }
        discount_amount
        discount_percentage
        email
        id
        name
        retailers {
          code
          name
        }
        ssn
        telephone
        updated_at
      }
      tags {
        id
        tag
      }
      year {
        active
        id
        name
      }
    `,
    retailersCreateRetailerClassRepresentative: `
      name
      email
      ssn
      telephone
      retailers {
        id
        code
        name
      }
      classes {
        id
        name
      }
      discount_amount
      discount_percentage
      updated_at
    `,
    retailersRetailer: `
      active
      address
      code
      city
      classes {
        active
        name
        updated_at
        year {
          name
        }
      }
      country
      embroidery_1
      embroidery_2
      embroidery_3
      extension_attributes
      hero_banner
      hero_banner_url
      internal_note
      logo
      logo_url
      logo_2
      logo_2_url
      logo_3
      logo_3_url
      logo_4
      logo_4_url
      marketing_url
      name
      priority
      periods {
        active
        from_date
        grace_payment_date
        to_date
        updated_at
      }
      personalization_logo
      personalization_logo_url
      post_code
      priority
      sales_responsible
      sales_team
      representative_email
      representative_name
      representative_ssn
      representative_telephone
      representatives {
        classes {
          id
          name
        }
        discount_amount
        discount_percentage
        email
        id
        name
        retailers {
          code
          name
        }
        ssn
        telephone
        updated_at
      }
      students_amount
      tags {
        id
        tag
      }
    `,
    retailersRetailers: `
      name
      code
      country
      tags {
        tag
      }
      active
      periods {
        active
        from_date
        to_date
      }
      priority
      updated_at
      priority
    `,
    retailersRetailerClass: `
      active
      code
      embroidery_1
      embroidery_2
      embroidery_3
      extension_attributes
      id
      name
      priority
      retailer_id
      retailer_year_id
      students_amount
      representatives {
        classes {
          id
          name
        }
        discount_amount
        discount_percentage
        email
        id
        name
        retailers {
          code
          name
        }
        ssn
        telephone
        updated_at
      }
      tags {
        id
        tag
      }
      year {
        active
        id
        name
      }
    `,
    retailersRetailerClasses: `
      name
      retailer {
        code
        name
      }
      year {
        name
      }
      active
      priority
      updated_at
      priority
    `,
    retailersRetailerTag: `
      tag
      updated_at
    `,
    retailersRetailerTags: `
      tag
      updated_at
    `,
    retailersRetailerYears: `
      name
      active
      updated_at
    `,
    retailersEducations: `
      name
      code
      active
      priority
      updated_at
      priority
    `,
    retailersRetailerClassRepresentative: `
      name
      email
      ssn
      telephone
      retailers {
        id
        code
        name
      }
      classes {
        id
        name
      }
      discount_amount
      discount_percentage
      updated_at
    `,
    retailersRetailerClassRepresentatives: `
      name
      email
      ssn
      telephone
      retailers {
        name
      }
      classes {
        name
      }
      discount_amount
      discount_percentage
      updated_at
    `,
    retailersUpdateRetailer: `
      active
      address
      code
      city
      classes {
        active
        name
        updated_at
        year {
          name
        }
      }
      country
      embroidery_1
      embroidery_2
      embroidery_3
      extension_attributes
      hero_banner
      hero_banner_url
      internal_note
      logo
      logo_url
      logo_2
      logo_2_url
      logo_3
      logo_3_url
      logo_4
      logo_4_url
      marketing_url
      name
      priority
      periods {
        active
        from_date
        grace_payment_date
        to_date
        updated_at
      }
      personalization_logo
      personalization_logo_url
      post_code
      priority
      sales_responsible
      sales_team
      representative_email
      representative_name
      representative_ssn
      representative_telephone
      representatives {
        classes {
          id
          name
        }
        discount_amount
        discount_percentage
        email
        id
        name
        retailers {
          code
          name
        }
        ssn
        telephone
        updated_at
      }
      students_amount
      tags {
        id
        tag
      }
    `,
    retailersUpdateRetailerClass: `
      active
      code
      embroidery_1
      embroidery_2
      embroidery_3
      extension_attributes
      id
      name
      priority
      retailer_id
      retailer_year_id
      students_amount
      representatives {
        classes {
          id
          name
        }
        discount_amount
        discount_percentage
        email
        id
        name
        retailers {
          code
          name
        }
        ssn
        telephone
        updated_at
      }
      tags {
        id
        tag
      }
      year {
        active
        id
        name
      }
    `,
    retailersUpdateRetailerClassRepresentative: `
      name
      email
      ssn
      telephone
      retailers {
        id
        code
        name
      }
      classes {
        id
        name
      }
      discount_amount
      discount_percentage
      updated_at
    `,
    retailersUpdateRetailerTag: `
      tag
      updated_at
    `,
    personalizationRules: `
      label
      active
      rules
      priority
    `
}

export default overridesMap
