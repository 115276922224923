import { useRedirect, Edit, SimpleForm, useTranslate } from 'react-admin'
import RetailerPeriodEditToolbar from './RetailerPeriodEditToolbar'
import RetailerPeriodFormInputs from './RetailerPeriodFormInputs'
import { useIndexRetailerMutation } from '../../apollo/magento/mutations/IndexRetailer.generated'

const RetailerPeriodEdit = (props) => {
    const translate = useTranslate()
    const redirect = useRedirect()
    const [indexRetailerMutation] = useIndexRetailerMutation()

    const onSuccess = (response: any) => {
        indexRetailerMutation({
            variables: {
                retailerId: response.data?.retailer?.code
            }
        })
        // Redirect with active tab open
        redirect(`/Retailer/${response.data?.retailer?.id}/periods`)
    }

    return (
        <Edit
            {...props}
            undoable={false}
            onSuccess={onSuccess}
            basePath={props.basepath}
            resource={props.resource}
            title={translate(`manager.resources.${props.resource}.edit`)}
        >
            <SimpleForm
                initialValues={{ retailer_id: props?.location?.state?.retailer_id }}
                toolbar={<RetailerPeriodEditToolbar />}
            >
                <RetailerPeriodFormInputs {...props} />
            </SimpleForm>
        </Edit>
    )
}

export default RetailerPeriodEdit
