import { Grid, Typography } from '@mui/material'
import {
    FormTab,
    Record,
    ResourceComponentProps,
    TabbedForm,
    TextInput,
    required,
    useTranslate
} from 'react-admin'
import { FormSpy } from 'react-final-form'

import DefaultToolbar from 'components/common/DefaultToolbar'
import { Dispatch, SetStateAction, useEffect } from 'react'
import { OccurencesList } from './OccurrencesList'
import { PersonalizationRule } from './utils/getRetailerTagOccurrences'

interface RetailerTagFormProps extends ResourceComponentProps {
    isPristineForm: any
    setIsPristineFormCallback: any
    setRecord?: Dispatch<SetStateAction<Record | undefined>>
    filteredPersonalizationRules?: PersonalizationRule[] | undefined
    record?: Record
    isEdit?: boolean
}

const RetailerTagForm: React.FC<RetailerTagFormProps> = ({
    isPristineForm,
    setIsPristineFormCallback,
    setRecord,
    filteredPersonalizationRules,
    ...props
}) => {
    const translate = useTranslate()

    useEffect(() => {
        if (props?.isEdit && setRecord && props?.record) {
            setRecord(props?.record)
            localStorage.setItem('currentTag', props?.record?.tag || '')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.record])

    return (
        <TabbedForm {...props} toolbar={<DefaultToolbar />}>
            <FormTab label={translate('manager.tabs.general')} path="/">
                <Grid container spacing={0}>
                    <Grid item md={7}>
                        <Typography variant="h6" gutterBottom>
                            {translate('manager.resources.tag.tag')}
                        </Typography>

                        <TextInput
                            source="tag"
                            variant="outlined"
                            label={translate('manager.resources.tag.name')}
                            validate={requiredValidate}
                            fullWidth
                        />
                    </Grid>

                    {filteredPersonalizationRules?.length && (
                        <OccurencesList
                            filteredPersonalizationRules={filteredPersonalizationRules}
                        />
                    )}
                </Grid>
            </FormTab>

            <FormSpy
                subscription={{ pristine: true }}
                onChange={(props) => {
                    setIsPristineFormCallback(props?.pristine)
                }}
            />
        </TabbedForm>
    )
}

const requiredValidate = [required()]

export default RetailerTagForm
