import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import RetailerTagList from './RetailerTagList'
import RetailerTagEdit from './RetailerTagEdit'
import RetailerTagCreate from './RetailerTagCreate'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    list: RetailerTagList,
    edit: RetailerTagEdit,
    create: RetailerTagCreate,
    icon: LocalOfferIcon
}
