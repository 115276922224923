import GroupsIcon from '@mui/icons-material/Groups'
import RetailerClassList from './RetailerClassList'
import RetailerClassEdit from './RetailerClassEdit'
import RetailerClassCreate from './RetailerClassCreate'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    list: RetailerClassList,
    edit: RetailerClassEdit,
    create: RetailerClassCreate,
    icon: GroupsIcon
}
