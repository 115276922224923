import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type IndexRetailerMutationVariables = Types.Exact<{
  retailerId: Types.Scalars['String'];
}>;


export type IndexRetailerMutation = { __typename?: 'Mutation', indexRetailer?: { __typename?: 'IndexResult', retailer_id: string, status: string } | null | undefined };


export const IndexRetailerDocument = gql`
    mutation IndexRetailer($retailerId: String!) {
  indexRetailer(retailerId: $retailerId) {
    retailer_id
    status
  }
}
    `;
export type IndexRetailerMutationFn = Apollo.MutationFunction<IndexRetailerMutation, IndexRetailerMutationVariables>;

/**
 * __useIndexRetailerMutation__
 *
 * To run a mutation, you first call `useIndexRetailerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIndexRetailerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [indexRetailerMutation, { data, loading, error }] = useIndexRetailerMutation({
 *   variables: {
 *      retailerId: // value for 'retailerId'
 *   },
 * });
 */
export function useIndexRetailerMutation(baseOptions?: Apollo.MutationHookOptions<IndexRetailerMutation, IndexRetailerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IndexRetailerMutation, IndexRetailerMutationVariables>(IndexRetailerDocument, options);
      }
export type IndexRetailerMutationHookResult = ReturnType<typeof useIndexRetailerMutation>;
export type IndexRetailerMutationResult = Apollo.MutationResult<IndexRetailerMutation>;
export type IndexRetailerMutationOptions = Apollo.BaseMutationOptions<IndexRetailerMutation, IndexRetailerMutationVariables>;