import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type CreateRetailerRetailerTagMutationVariables = Types.Exact<{
  retailerId: Types.Scalars['Int'];
  retailerTagId: Types.Scalars['Int'];
}>;


export type CreateRetailerRetailerTagMutation = { __typename?: 'Mutation', createRetailerRetailerTag?: { __typename?: 'retailersRetailerRetailerTag', retailer_id: number, retailer_tag_id: number } | null | undefined };


export const CreateRetailerRetailerTagDocument = gql`
    mutation CreateRetailerRetailerTag($retailerId: Int!, $retailerTagId: Int!) {
  createRetailerRetailerTag: retailersCreateRetailerRetailerTag(
    retailer_id: $retailerId
    retailer_tag_id: $retailerTagId
  ) {
    retailer_id
    retailer_tag_id
  }
}
    `;
export type CreateRetailerRetailerTagMutationFn = Apollo.MutationFunction<CreateRetailerRetailerTagMutation, CreateRetailerRetailerTagMutationVariables>;

/**
 * __useCreateRetailerRetailerTagMutation__
 *
 * To run a mutation, you first call `useCreateRetailerRetailerTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRetailerRetailerTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRetailerRetailerTagMutation, { data, loading, error }] = useCreateRetailerRetailerTagMutation({
 *   variables: {
 *      retailerId: // value for 'retailerId'
 *      retailerTagId: // value for 'retailerTagId'
 *   },
 * });
 */
export function useCreateRetailerRetailerTagMutation(baseOptions?: Apollo.MutationHookOptions<CreateRetailerRetailerTagMutation, CreateRetailerRetailerTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRetailerRetailerTagMutation, CreateRetailerRetailerTagMutationVariables>(CreateRetailerRetailerTagDocument, options);
      }
export type CreateRetailerRetailerTagMutationHookResult = ReturnType<typeof useCreateRetailerRetailerTagMutation>;
export type CreateRetailerRetailerTagMutationResult = Apollo.MutationResult<CreateRetailerRetailerTagMutation>;
export type CreateRetailerRetailerTagMutationOptions = Apollo.BaseMutationOptions<CreateRetailerRetailerTagMutation, CreateRetailerRetailerTagMutationVariables>;