import {
    TextInput,
    BooleanInput,
    useTranslate,
    required,
    TabbedForm,
    FormTab,
    NumberInput
} from 'react-admin'
import { Grid, Typography } from '@mui/material'
import { FormSpy } from 'react-final-form'

import DefaultToolbar from 'components/common/DefaultToolbar'

const EducationForm = ({ isPristineForm, setIsPristineFormCallback, ...props }) => {
    const translate = useTranslate()
    return (
        <TabbedForm {...props} toolbar={<DefaultToolbar />} redirect={props.basePath}>
            <FormTab label={translate('manager.tabs.general')} path="/">
                <Grid container spacing={0}>
                    <Grid item md={7}>
                        <Typography variant="h6" gutterBottom>
                            {translate('manager.resources.education.education')}
                        </Typography>
                        <TextInput
                            source="name"
                            variant="outlined"
                            label={translate('manager.resources.education.name')}
                            validate={requiredValidate}
                            fullWidth
                        />
                        <TextInput
                            source="code"
                            variant="outlined"
                            label={translate('manager.resources.education.code')}
                            validate={requiredValidate}
                            fullWidth
                        />
                        <NumberInput
                            source="priority"
                            variant="outlined"
                            label={translate('manager.resources.retailerClass.priority')}
                            fullWidth
                        />
                        <BooleanInput
                            source="active"
                            label={translate('manager.resources.general.published')}
                            defaultValue={true}
                        />
                    </Grid>
                </Grid>
            </FormTab>
            <FormSpy
                subscription={{ pristine: true }}
                onChange={(props) => {
                    setIsPristineFormCallback(props?.pristine)
                }}
            />
        </TabbedForm>
    )
}

const requiredValidate = [required()]

export default EducationForm
