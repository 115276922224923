import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type RetailersImportRetailerClassesMutationVariables = Types.Exact<{
  file: Types.Scalars['retailersUpload'];
}>;


export type RetailersImportRetailerClassesMutation = { __typename?: 'Mutation', retailersImportRetailerClasses?: boolean | null | undefined };


export const RetailersImportRetailerClassesDocument = gql`
    mutation RetailersImportRetailerClasses($file: retailersUpload!) {
  retailersImportRetailerClasses(file: $file)
}
    `;
export type RetailersImportRetailerClassesMutationFn = Apollo.MutationFunction<RetailersImportRetailerClassesMutation, RetailersImportRetailerClassesMutationVariables>;

/**
 * __useRetailersImportRetailerClassesMutation__
 *
 * To run a mutation, you first call `useRetailersImportRetailerClassesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRetailersImportRetailerClassesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [retailersImportRetailerClassesMutation, { data, loading, error }] = useRetailersImportRetailerClassesMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useRetailersImportRetailerClassesMutation(baseOptions?: Apollo.MutationHookOptions<RetailersImportRetailerClassesMutation, RetailersImportRetailerClassesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RetailersImportRetailerClassesMutation, RetailersImportRetailerClassesMutationVariables>(RetailersImportRetailerClassesDocument, options);
      }
export type RetailersImportRetailerClassesMutationHookResult = ReturnType<typeof useRetailersImportRetailerClassesMutation>;
export type RetailersImportRetailerClassesMutationResult = Apollo.MutationResult<RetailersImportRetailerClassesMutation>;
export type RetailersImportRetailerClassesMutationOptions = Apollo.BaseMutationOptions<RetailersImportRetailerClassesMutation, RetailersImportRetailerClassesMutationVariables>;