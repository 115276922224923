import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type DeleteRetailerClassRetailerTagMutationVariables = Types.Exact<{
  retailerTagId: Types.Scalars['Int'];
  retailerClassId: Types.Scalars['Int'];
}>;


export type DeleteRetailerClassRetailerTagMutation = { __typename?: 'Mutation', deleteRetailerClassRetailerTag?: { __typename?: 'retailersRetailerClassRetailerTag', retailer_tag_id: number, retailer_class_id: number } | null | undefined };


export const DeleteRetailerClassRetailerTagDocument = gql`
    mutation DeleteRetailerClassRetailerTag($retailerTagId: Int!, $retailerClassId: Int!) {
  deleteRetailerClassRetailerTag: retailersDeleteRetailerClassRetailerTag(
    retailer_tag_id: $retailerTagId
    retailer_class_id: $retailerClassId
  ) {
    retailer_tag_id
    retailer_class_id
  }
}
    `;
export type DeleteRetailerClassRetailerTagMutationFn = Apollo.MutationFunction<DeleteRetailerClassRetailerTagMutation, DeleteRetailerClassRetailerTagMutationVariables>;

/**
 * __useDeleteRetailerClassRetailerTagMutation__
 *
 * To run a mutation, you first call `useDeleteRetailerClassRetailerTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRetailerClassRetailerTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRetailerClassRetailerTagMutation, { data, loading, error }] = useDeleteRetailerClassRetailerTagMutation({
 *   variables: {
 *      retailerTagId: // value for 'retailerTagId'
 *      retailerClassId: // value for 'retailerClassId'
 *   },
 * });
 */
export function useDeleteRetailerClassRetailerTagMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRetailerClassRetailerTagMutation, DeleteRetailerClassRetailerTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRetailerClassRetailerTagMutation, DeleteRetailerClassRetailerTagMutationVariables>(DeleteRetailerClassRetailerTagDocument, options);
      }
export type DeleteRetailerClassRetailerTagMutationHookResult = ReturnType<typeof useDeleteRetailerClassRetailerTagMutation>;
export type DeleteRetailerClassRetailerTagMutationResult = Apollo.MutationResult<DeleteRetailerClassRetailerTagMutation>;
export type DeleteRetailerClassRetailerTagMutationOptions = Apollo.BaseMutationOptions<DeleteRetailerClassRetailerTagMutation, DeleteRetailerClassRetailerTagMutationVariables>;