export const resourceMap = {
    Education: {
        servicePrefix: 'retailers'
    },
    Package: {
        servicePrefix: 'packages'
    },
    PackageProduct: {
        servicePrefix: 'packages'
    },
    PackageGalleryItem: {
        servicePrefix: 'packages'
    },
    PackageGroup: {
        servicePrefix: 'packages'
    },
    PackageGroupGalleryItem: {
        servicePrefix: 'packages'
    },
    PackageGroupCollection: {
        servicePrefix: 'packages'
    },
    PackageGroupCollectionGalleryItem: {
        servicePrefix: 'packages'
    },
    Rule: {
        servicePrefix: 'personalization'
    },
    Preset: {
        servicePrefix: 'configurator'
    },
    Retailer: {
        servicePrefix: 'retailers'
    },
    RetailerClass: {
        servicePrefix: 'retailers'
    },
    RetailerClassRepresentative: {
        servicePrefix: 'retailers'
    },
    RetailerPeriod: {
        servicePrefix: 'retailers'
    },
    RetailerTag: {
        servicePrefix: 'retailers'
    },
    RetailerYear: {
        servicePrefix: 'retailers'
    }
}
