import Button from '@material-ui/core/Button'
import CheckBox from '@material-ui/core/Checkbox'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Fab from '@material-ui/core/Fab'
import { amber } from '@material-ui/core/colors'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import { useDownloadModelLazyQuery } from 'apollo/configurator/queries/DownloadModel.generated'
import { useFlushActionsCacheMutation } from 'apollo/personalization/mutations/FlushActionsCache.generated'
import clsx from 'clsx'
// import { ModelContext } from 'context/model/ModelContext'
import React /* , { useContext } */ from 'react'
import { exportData, handleConfirm } from './helpers/ImportExportModel'

interface IDownloadButton {
    id: number
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            alignItems: 'center',
            marginLeft: 25,
            rotation: '180deg'
        },
        wrapper: {
            margin: theme.spacing(1),
            position: 'relative'
        },
        editedButton: {
            width: 150,
            position: 'absolute',
            margin: 0,
            zIndex: -1,
            height: 50,
            borderRadius: '0 10px 10px 0 ',
            backgroundColor: amber[500],
            display: 'flex',
            fontSize: 18,
            alignItems: 'center',
            paddingLeft: 40,
            fontWeight: 'bold'
        },
        buttonSuccess: {
            backgroundColor: theme.palette.primary.main,
            '&:hover': {
                backgroundColor: theme.palette.primary.dark
            }
        },
        buttonFailure: {
            backgroundColor: theme.palette.error.main,
            '&:hover': {
                backgroundColor: theme.palette.error.dark
            }
        },
        buttonNotExported: {
            backgroundColor: theme.palette.primary.light,
            '&:hover': {
                backgroundColor: theme.palette.primary.main
            }
        },
        fabProgress: {
            color: theme.palette.primary.light,
            position: 'absolute',
            top: -6,
            left: -6,
            zIndex: 1
        },
        buttonProgress: {
            color: theme.palette.primary.main,
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12
        },
        slideWrapper: {
            position: 'relative',
            width: 300,
            height: 50,
            overflow: 'hidden',
            marginLeft: -25
        }
    })
)

const DownloadButton = ({ id }: IDownloadButton) => {
    // const { state } = useContext(ModelContext)
    const [mutationError] = React.useState(false)
    const [open, setOpen] = React.useState(false)
    const [inclPresets, setInclPresets] = React.useState(false)

    const [downloadModel, { loading }] = useDownloadModelLazyQuery({
        onCompleted: (data) => {
            exportData({
                model: JSON.parse(data.configuratorExportJSONModel?.model ?? '[]'),
                presets: JSON.parse(data.configuratorExportJSONModel?.presets ?? '[]')
            })
        },
        fetchPolicy: 'network-only'
    })

    const classes = useStyles()
    const buttonClassname = clsx({
        [classes.buttonSuccess]: true,
        [classes.buttonFailure]: mutationError
        // ,[classes.buttonNotExported]: state.hasChanges
    })

    const [flushActionsCache] = useFlushActionsCacheMutation()

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <>
            <div className={classes.root}>
                <div className={classes.wrapper}>
                    <Fab
                        aria-label="save"
                        color="primary"
                        className={buttonClassname}
                        onClick={handleClickOpen}
                    >
                        <CloudDownloadIcon />
                    </Fab>
                    {loading && <CircularProgress size={68} className={classes.fabProgress} />}
                </div>
            </div>
            <Dialog
                open={open}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">
                    {'Export the model to a file and Download.'}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <CheckBox
                            checked={inclPresets}
                            style={{ top: '-1px', position: 'relative' }}
                            onChange={(evt, val) => {
                                setInclPresets(val)
                            }}
                        ></CheckBox>{' '}
                        Include Presets?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="contained" color="default">
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            handleConfirm(downloadModel, id, inclPresets)
                            flushActionsCache()
                            setOpen(false)
                        }}
                        variant="contained"
                        color="primary"
                    >
                        Export
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default DownloadButton
