import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type RetailersImportRetailersMutationVariables = Types.Exact<{
  file: Types.Scalars['retailersUpload'];
}>;


export type RetailersImportRetailersMutation = { __typename?: 'Mutation', retailersImportRetailers?: boolean | null | undefined };


export const RetailersImportRetailersDocument = gql`
    mutation RetailersImportRetailers($file: retailersUpload!) {
  retailersImportRetailers(file: $file)
}
    `;
export type RetailersImportRetailersMutationFn = Apollo.MutationFunction<RetailersImportRetailersMutation, RetailersImportRetailersMutationVariables>;

/**
 * __useRetailersImportRetailersMutation__
 *
 * To run a mutation, you first call `useRetailersImportRetailersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRetailersImportRetailersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [retailersImportRetailersMutation, { data, loading, error }] = useRetailersImportRetailersMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useRetailersImportRetailersMutation(baseOptions?: Apollo.MutationHookOptions<RetailersImportRetailersMutation, RetailersImportRetailersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RetailersImportRetailersMutation, RetailersImportRetailersMutationVariables>(RetailersImportRetailersDocument, options);
      }
export type RetailersImportRetailersMutationHookResult = ReturnType<typeof useRetailersImportRetailersMutation>;
export type RetailersImportRetailersMutationResult = Apollo.MutationResult<RetailersImportRetailersMutation>;
export type RetailersImportRetailersMutationOptions = Apollo.BaseMutationOptions<RetailersImportRetailersMutation, RetailersImportRetailersMutationVariables>;