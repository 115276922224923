import { Card, CardContent, CardHeader } from '@material-ui/core'
// import { useSelector } from 'react-redux'

// TODO: make this a typescript file
const Dashboard = (props) => {
    // const theme = useSelector((state) => state.theme)
    const title = 'Manager'
    return (
        <Card {...props}>
            <CardHeader title="Dashboard" />
            <CardContent>{`Welcome to the ${title}`}</CardContent>
        </Card>
    )
}
export default Dashboard
