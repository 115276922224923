/* eslint-disable no-unused-vars */
import { useContext, useEffect, useState } from 'react'
import SortableTree, { ExtendedNodeData, TreeItem } from 'react-sortable-tree'
import 'react-sortable-tree/style.css'
import _ from 'lodash'

// MUI
import { Button, CircularProgress, createStyles, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ReplayIcon from '@mui/icons-material/Replay'

// GQL
import { usePresetNodeQuery } from 'apollo/configurator/queries/PresetNode.generated'

import { INodeWithPreset, IPresetTreeView, RemoveAbleItemProps } from 'ts/interfaces'
import RemovePresetNodePopup from './RemovePresetNodePopup'
import { TreeViewWrapper } from './styling/TreeViewWrapper'
import { RemoveButton } from 'components/modelManager/atoms/buttons/Buttons'
import { getNodeStyle, getNodeTitle } from './PresetNodeRenderHelper'
import VisibilityButton from './VisiblityButton'
import { PresetModelContext } from 'context/preset-model/PresetModelContext'
import {
    findPresetByPresetIdInArray,
    formatPresetTreeState
} from 'components/presetManager/helpers/TreeHelperFunctions'
import { COLORS } from 'layout/theme/colors'
import { useTranslate } from 'react-admin'

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            height: '100%',
            position: 'relative',
            overflow: 'scroll',
            marginBottom: '50px'
        },
        nodeActionButton: {
            height: '100%',
            color: `${COLORS.theme.grey.main}`,
            '&:hover': {
                color: `${COLORS.theme.grey.dark}`,
                backgroundColor: 'transparent'
            }
        }
    })
)

const TreeView = ({ handleNodeClick, reRenderCallback, presetId, rootNode }: IPresetTreeView) => {
    const { refetch: refetchPresetNodeData } = usePresetNodeQuery({
        variables: { configuratorPresetNodeId: 0 as any },
        skip: true
    })

    const classes = useStyles()
    const translate = useTranslate()
    const [uniqueKey, setUniqueKey] = useState<number>(0)
    const [syncing, setSyncing] = useState<boolean>(false)
    const [treeState, setTreeState] = useState<TreeItem[] | null>()
    const [selectedNode, setSelectedNode] = useState<INodeWithPreset | null>(rootNode as any)
    const { state: presetModelState, setState: setPresetModelState } =
        useContext(PresetModelContext)

    const [removeableItem, setRemoveableItem] = useState<RemoveAbleItemProps | null>()

    // Initialize tree with initial rootnode
    useEffect(() => {
        if (!!rootNode) intializeTree(rootNode)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rootNode])

    const intializeTree = async (rootNode: any): Promise<TreeItem[]> => {
        if (rootNode) {
            // Make sure the tree expands when edited, else the tree would collapse constantly.
            const tree = [formatPresetTreeState(rootNode, !!presetModelState.hasEdited)]

            // Parse preset nodes onto node objects
            const newTree = await parsePresetNodes(tree)

            // Set tree state after it has been parsed, automatically updates local treeState by useEffect
            setTreeState(newTree)

            // Update treeState in context
            setPresetModelState({
                ...presetModelState,
                treeState: tree, // TODO: Why tree and not newTree here? newTree breaks hasPreset it seems
                reRenderTree: false
            })

            // If there are no changes, select the first node. This shows the form directly on model selection.
            !presetModelState.hasEdited && handleNodeClick(newTree?.[0]?.id, 'null')

            // return
            return newTree
        } else {
            handleNodeClick(0, 'null')
            setPresetModelState({ ...presetModelState, currentModel: '' })
        }
        return []
    }

    // Align local treeState with presetModelContext treeState
    useEffect(() => {
        if (presetModelState.treeState !== treeState) setTreeState(presetModelState.treeState)
        return () => {}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [presetModelState.treeState])

    // Handle re-render from context
    useEffect(() => {
        if (!!presetModelState.reRenderTree) {
            setPresetModelState({ ...presetModelState, reRenderTree: false })
            reRender()
        }
        return () => {}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [presetModelState.reRenderTree])

    // Update selectedNode in PresetModelContext
    useEffect(() => {
        if (!!selectedNode && selectedNode !== presetModelState.selectedNode)
            setPresetModelState({
                ...presetModelState,
                selectedNode: selectedNode,
                reRenderForm: true
            })
        return () => {}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedNode])

    // Re-render entire component with callback or locally
    const reRender = async (entireComponent = false): Promise<void> => {
        if (entireComponent) {
            reRenderCallback()
        } else {
            setSyncing(true)
            setPresetModelState({
                ...presetModelState,
                reRenderTree: false
            })
            await parsePresetNodes(presetModelState.treeState)
                .then((newTree) => setTreeState(newTree))
                .finally(
                    async () => await reRenderSortableTreeComponent().then(() => setSyncing(false))
                )
        }
    }

    // Update key
    const reRenderSortableTreeComponent = async (): Promise<void> => {
        setUniqueKey(uniqueKey + 1)
    }

    // Adds presetData to the node objects in the tree.
    // Which then can be used in the generateNodeProps function of react-sortable-tree
    const parsePresetNodes = async (tree: TreeItem[]): Promise<TreeItem[]> => {
        let previousObject: TreeItem = []
        return _.cloneDeepWith(tree, (_, __, object: TreeItem | undefined) => {
            // This is a node object which gets looped over a couple of times by cloneDeepWith.
            // Using previousObject is is possible to confirm if we are looping over a new node.
            if (object && object.hasOwnProperty('expanded') && object !== previousObject) {
                // Update previous object
                previousObject = object

                // Parse
                object = parseSinglePresetNode(object)
            }
        })
    }

    // Parses a presetNode onto a node object
    const parseSinglePresetNode = async (node: TreeItem): Promise<TreeItem> => {
        const { has_nodes_of_presets: hasNodesOfPresets } = node
        const index = findPresetByPresetIdInArray(hasNodesOfPresets, presetId)

        // Assign presetId to every node object
        node.presetId = presetId

        // A preset exists for this node
        if (index > -1) {
            // get the presetNodeId from the hasNodesOfPresets array by index
            const presetNodeId = hasNodesOfPresets[index]?.preset_node_id

            // Assign presetNodeId to node object
            node.presetNodeId = presetNodeId

            // fetch presetNode
            await refetchPresetNodeData({
                configuratorPresetNodeId: presetNodeId
            })
                ?.then(({ data: { configuratorPresetNode } }) => {
                    // Assign presetNode to node object
                    node.presetNode = configuratorPresetNode
                })
                .finally(() => {
                    return node
                })
        }
        return node
    }

    const getNodeActions = (node: TreeItem): JSX.Element[] => {
        const { id, parent_id, presetNode } = node
        const buttons: JSX.Element[] = []

        // All visiblity button
        !!node.children &&
            node.children.length > 0 &&
            buttons.push(
                <VisibilityButton
                    node={node}
                    presetId={presetId}
                    className={classes.nodeActionButton}
                    reRenderCallback={() => reRender(true)}
                    includingChildNodes
                />
            )

        // Visiblity button
        buttons.push(
            <VisibilityButton
                presetId={presetId}
                node={node}
                className={classes.nodeActionButton}
                reRenderCallback={() => reRender(true)}
            />
        )

        // Remove button
        !!presetNode &&
            buttons.push(
                <RemoveButton
                    key={id}
                    className={classes.nodeActionButton}
                    onClick={() => {
                        setRemoveableItem({
                            id: +id,
                            parent_id: parent_id,
                            node: node as TreeItem
                        })
                    }}
                />
            )

        // Return
        return buttons
    }

    const generateNodeProps = (extendedNode): { [index: string]: any } => {
        const node = extendedNode?.node
        const { id, parent_id } = node

        // Add path to node object
        node.path = extendedNode.path

        return {
            style: getNodeStyle(node),
            title: getNodeTitle(node),
            buttons: getNodeActions(node),
            onClick: () => {
                handleNodeClick(id, parent_id)
                setSelectedNode(node)
            }
        }
    }

    if (!treeState) return <CircularProgress />
    return (
        <div className={classes.root}>
            {treeState && (
                <TreeViewWrapper>
                    <Grid container justifyContent="flex-end">
                        <Grid item md={3}>
                            <Button
                                className="refresh-button"
                                onClick={() => reRender(true)}
                                endIcon={<ReplayIcon />}
                            >
                                {translate('manager.resources.preset.manager.sync')}
                            </Button>
                        </Grid>
                        <Grid item md={12}>
                            {!syncing && (
                                <SortableTree
                                    key={uniqueKey}
                                    canDrag={false}
                                    isVirtualized={false}
                                    scaffoldBlockPxWidth={30}
                                    getNodeKey={({ node }) => node?.id}
                                    treeData={treeState}
                                    onChange={(treeData: any) => {
                                        setTreeState(treeData)
                                        setPresetModelState({
                                            ...presetModelState,
                                            treeState: treeData
                                        })
                                    }}
                                    theme={{ rowHeight: 55 }}
                                    generateNodeProps={(node: ExtendedNodeData) =>
                                        generateNodeProps(node)
                                    }
                                />
                            )}
                        </Grid>
                    </Grid>
                </TreeViewWrapper>
            )}
            {!!removeableItem && (
                <RemovePresetNodePopup
                    removeableItem={removeableItem}
                    treeState={treeState}
                    onSubmitCallback={(tree: TreeItem[]) => {
                        setTreeState(tree)
                        setPresetModelState({
                            ...presetModelState,
                            treeState: tree,
                            reRenderForm: true,
                            reRenderTree: true // Because it is possible that has_descendants color needs to be removed from parent nodes
                        })
                    }}
                    setRemovableItemCallback={(item: RemoveAbleItemProps | null) => {
                        setRemoveableItem(item)
                    }}
                />
            )}
        </div>
    )
}

export default TreeView
