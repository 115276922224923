import {
    BooleanInput,
    useTranslate,
    TabbedForm,
    FormTab,
    TextInput,
    required,
    NumberInput,
    regex
    // Record,
    // TopToolbar,
    // useRedirect
} from 'react-admin'
import { Box, Grid, Typography /* , Button */ } from '@material-ui/core'
// import AddIcon from '@mui/icons-material/Add'
import { makeStyles } from '@material-ui/core/styles'
import { FormSpy } from 'react-final-form'

import TextAreaInput from '../../components/formfields/TextAreaInput'
import CountriesSelect from '../../components/formfields/CountriesSelect'
import DefaultToolbar from '../../components/common/DefaultToolbar'
import RetailerEditToolbar from './RetailerEditToolbar'
import RetailerTagsInput from './RetailerTagsInput'
import RetailerPeriodList from '../retailerPeriod/RetailerPeriodList'
// import RetailerClassList from '../retailerClass/RetailerClassList'
import UploadField from 'components/formfields/uploadField/UploadField'
import { formStyles } from 'layout/styles/formStyles'
// import RetailerClassRepresentativeListAsArrayField from 'resources/retailerClassRepresentative/RetailerClassRepresentativeListAsArrayField'
import { Variables } from 'components/variables'
// import { useRetailerSalesTeamsQuery } from 'apollo/retailer_manager/queries/RetailerTeams.generated'
// import { useRetailerSalesResponsiblesQuery } from 'apollo/retailer_manager/queries/RetailerResponsibles.generated'

const useStyles = makeStyles(formStyles)

// const useCustomStyles = makeStyles({
//     paddingBottom: {
//         paddingBottom: 16
//     }
// })

// const RetailerClassesTab = (props: Record | undefined) => {
//     const translate = useTranslate()
//     const classes = useCustomStyles()
//     const redirect = useRedirect()

//     const handleLinkClass = () => {
//         redirect(
//             'create',
//             '/RetailerClass',
//             props?.record?.id,
//             {},
//             { retailerId: props?.record?.id }
//         )
//     }

//     return (
//         <>
//             <TopToolbar>
//                 <Button color="primary" variant={'outlined'} onClick={handleLinkClass}>
//                     <AddIcon />
//                     {translate('manager.resources.retailer.link_class')}
//                 </Button>
//             </TopToolbar>
//             {props?.record?.classes?.length ? (
//                 <RetailerClassList
//                     filter={{ retailer_id: parseInt(props?.record?.id) }}
//                     className={classes.paddingBottom}
//                 />
//             ) : (
//                 <Typography variant="body1" gutterBottom className={classes.paddingBottom}>
//                     {translate('manager.resources.retailer.no_classes')}
//                 </Typography>
//             )}
//         </>
//     )
// }

const RetailerForm = ({ isPristineForm, setIsPristineFormCallback, ...props }) => {
    const translate = useTranslate()
    const formStyle = useStyles()
    const validateRegex = regex(/^[\w-]+$/, translate('manager.regex.az09'))

    // const { data: { retailersRetailerSalesTeams: teams } = {} } = useRetailerSalesTeamsQuery()
    // const { data: { retailersRetailerSalesResponsibles: responsibles } = {} } =
    //     useRetailerSalesResponsiblesQuery()

    return (
        <TabbedForm
            {...props}
            toolbar={props.isEdit ? <RetailerEditToolbar {...props} /> : <DefaultToolbar />}
            redirect={props.basePath}
        >
            <FormTab label={translate('manager.tabs.general')} path="/">
                <Grid container className={formStyle.fullWidth}>
                    <Grid container>
                        <Grid item md={7}>
                            <Typography variant="h6" gutterBottom>
                                {translate('manager.resources.retailer.retailer')}
                            </Typography>
                        </Grid>
                        <Grid item md={1} />
                        <Grid item md={4}>
                            <Typography variant="h6" gutterBottom>
                                {translate('manager.resources.retailer.representative')}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item md={7}>
                        <Grid item md={12}>
                            <TextInput
                                fullWidth
                                source="code"
                                variant="outlined"
                                label={translate('manager.resources.retailer.code')}
                                validate={[required(), validateRegex]}
                                helperText={translate('manager.regex.az09')}
                                disabled={props.isEdit}
                            />
                            <TextInput
                                fullWidth
                                source="name"
                                variant="outlined"
                                label={translate('manager.resources.retailer.name')}
                                validate={requiredValidate}
                            />
                            <TextInput
                                source="address"
                                variant="outlined"
                                label={translate('manager.resources.retailer.address')}
                                fullWidth
                                validate={requiredValidate}
                            />
                            <TextInput
                                source="post_code"
                                variant="outlined"
                                label={translate('manager.resources.retailer.post_code')}
                                fullWidth
                                validate={requiredValidate}
                            />
                            <TextInput
                                source="city"
                                variant="outlined"
                                label={translate('manager.resources.retailer.city')}
                                fullWidth
                                validate={requiredValidate}
                            />
                            <CountriesSelect fullWidth validate={requiredValidate} />

                            {/*<SelectInput*/}
                            {/*    {...props}*/}
                            {/*    source="sales_team"*/}
                            {/*    optionText="name"*/}
                            {/*    optionValue="id"*/}
                            {/*    variant="outlined"*/}
                            {/*    fullWidth*/}
                            {/*    choices={teams?.data || []}*/}
                            {/*/>*/}

                            {/*<SelectInput*/}
                            {/*    {...props}*/}
                            {/*    source="sales_responsible"*/}
                            {/*    optionText="name"*/}
                            {/*    optionValue="id"*/}
                            {/*    variant="outlined"*/}
                            {/*    fullWidth*/}
                            {/*    choices={responsibles?.data || []}*/}
                            {/*/>*/}

                            <TextInput
                                source="embroidery_1"
                                variant="outlined"
                                label={translate('manager.resources.retailer.embroidery_1')}
                                fullWidth
                            />
                            <TextInput
                                source="embroidery_2"
                                variant="outlined"
                                label={translate('manager.resources.retailer.embroidery_2')}
                                fullWidth
                            />
                            <TextInput
                                source="embroidery_3"
                                variant="outlined"
                                label={translate('manager.resources.retailer.embroidery_3')}
                                fullWidth
                            />
                            <TextInput
                                source="marketing_url"
                                variant="outlined"
                                label={translate('manager.resources.retailer.marketing_url')}
                                fullWidth
                                helperText={translate('manager.regex.az09')}
                                validate={validateRegex}
                            />
                            {/*<NumberInput*/}
                            {/*    source="students_amount"*/}
                            {/*    variant="outlined"*/}
                            {/*    label={translate('manager.resources.retailer.students_amount')}*/}
                            {/*    fullWidth*/}
                            {/*/>*/}
                            <NumberInput
                                source="priority"
                                variant="outlined"
                                label={translate('manager.resources.retailer.priority')}
                                fullWidth
                            />
                            <Box mb={2} mt={2}>
                                <TextAreaInput
                                    fullWidth
                                    source="internal_note"
                                    name="internal_note"
                                    label={translate('manager.resources.retailer.internal_note')}
                                    rows={3}
                                    multiline={true}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid item md={1} />
                    <Grid item md={4}>
                        <Grid item md={12} className={formStyle.flexStart}>
                            <TextInput
                                source="representative_name"
                                variant="outlined"
                                label={translate('manager.resources.retailer.representative_name')}
                                fullWidth
                            />
                            <TextInput
                                source="representative_email"
                                variant="outlined"
                                label={translate('manager.resources.retailer.representative_email')}
                                fullWidth
                            />
                            <TextInput
                                source="representative_telephone"
                                variant="outlined"
                                label={translate(
                                    'manager.resources.retailer.representative_telephone'
                                )}
                                fullWidth
                            />
                            {props?.record?.id && (
                                <>
                                    <Typography variant="h6" gutterBottom>
                                        {translate('manager.resources.retailer.tags')}
                                    </Typography>
                                    <RetailerTagsInput retailerId={props.record.id} />
                                </>
                            )}
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item md={12}>
                            <Box mb={2}>
                                <BooleanInput
                                    row
                                    source="active"
                                    label={translate('manager.resources.general.published')}
                                    defaultValue={true}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </FormTab>
            {props?.record?.id && (
                <FormTab label={translate('manager.tabs.periods')} path="periods">
                    <RetailerPeriodList filter={{ retailer_id: parseInt(props?.record?.id) }} />
                </FormTab>
            )}
            {/*{!!props.isEdit && (*/}
            {/*    <FormTab label={translate('manager.tabs.classes')} path="classes">*/}
            {/*        <RetailerClassesTab props={props} id={props?.id} />*/}
            {/*    </FormTab>*/}
            {/*)}*/}
            <FormTab label={translate('manager.tabs.images')} path="images">
                <Grid container className={formStyle.fullWidth}>
                    <Grid item md={12}>
                        <Typography variant="h6" gutterBottom>
                            {translate('manager.tabs.images')}
                        </Typography>
                        <UploadField
                            data={props?.record?.logo}
                            url={props?.record?.logo}
                            source="logo"
                            label={translate('manager.resources.retailer.logo')}
                        />
                        <UploadField
                            data={props?.record?.logo_2}
                            url={props?.record?.logo_2}
                            source="logo_2"
                            label={`${translate('manager.resources.retailer.logo')} 2`}
                        />
                        <UploadField
                            data={props?.record?.logo_3}
                            url={props?.record?.logo_3}
                            source="logo_3"
                            label={`${translate('manager.resources.retailer.logo')} 3`}
                        />
                        <UploadField
                            data={props?.record?.logo_4}
                            url={props?.record?.logo_4}
                            source="logo_4"
                            label={`${translate('manager.resources.retailer.logo')} 4`}
                        />
                        <UploadField
                            data={props?.record?.personalization_logo}
                            url={props?.record?.personalization_logo}
                            source="personalization_logo"
                            label={translate('manager.resources.retailer.personalization_logo')}
                        />
                        <UploadField
                            data={props?.record?.hero_banner}
                            url={props?.record?.hero_banner}
                            source="hero_banner"
                            label={translate('manager.resources.retailer.hero_banner')}
                        />
                    </Grid>
                </Grid>
            </FormTab>
            {/*{!!props.isEdit && (*/}
            {/*    <FormTab label={translate('manager.tabs.contact_persons')} path="contact-persons">*/}
            {/*        <Grid container className={formStyle.fullWidth}>*/}
            {/*            <RetailerClassRepresentativeListAsArrayField {...props} />*/}
            {/*        </Grid>*/}
            {/*    </FormTab>*/}
            {/*)}*/}
            <FormTab label={translate('manager.tabs.variables')} path="variables">
                <Variables record={props?.record} entityType="retailer" />
            </FormTab>
            <FormSpy
                subscription={{ pristine: true }}
                onChange={(props) => {
                    setIsPristineFormCallback(props?.pristine)
                }}
            />
        </TabbedForm>
    )
}

const requiredValidate = [required()]

export default RetailerForm
