import { useState } from 'react'
import { Create, useTranslate } from 'react-admin'

import { BackFromEditorButton } from 'components/common/BackFromEditorButton'

import RetailerClassRepresentativeForm from './RetailerClassRepresentativeForm'

const RetailerClassRepresentativeCreate = (props) => {
    const translate = useTranslate()
    const [isPristineForm, setIsPristineForm] = useState(true)

    return (
        <>
            <BackFromEditorButton
                isPristineForm={isPristineForm}
                backToRouteURL="/retailerClassRepresentative"
            />
            <Create title={translate('manager.resources.contactPersons.create')} {...props}>
                <RetailerClassRepresentativeForm
                    isPristineForm={isPristineForm}
                    setIsPristineFormCallback={setIsPristineForm}
                />
            </Create>
        </>
    )
}

export default RetailerClassRepresentativeCreate
