import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type RetailersRetailerClassQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['ID']>;
}>;


export type RetailersRetailerClassQuery = { __typename?: 'Query', retailersRetailerClass?: { __typename?: 'retailersRetailerClass', name: string, year?: { __typename?: 'retailersRetailerYear', name: string } | null | undefined, retailer?: { __typename?: 'retailersRetailer', name: string } | null | undefined, tags: Array<{ __typename?: 'retailersRetailerTag', id: string, tag: string }> } | null | undefined };


export const RetailersRetailerClassDocument = gql`
    query RetailersRetailerClass($id: ID) {
  retailersRetailerClass(id: $id) {
    name
    year {
      name
    }
    retailer {
      name
    }
    tags {
      id
      tag
    }
  }
}
    `;

/**
 * __useRetailersRetailerClassQuery__
 *
 * To run a query within a React component, call `useRetailersRetailerClassQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetailersRetailerClassQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetailersRetailerClassQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRetailersRetailerClassQuery(baseOptions?: Apollo.QueryHookOptions<RetailersRetailerClassQuery, RetailersRetailerClassQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RetailersRetailerClassQuery, RetailersRetailerClassQueryVariables>(RetailersRetailerClassDocument, options);
      }
export function useRetailersRetailerClassLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RetailersRetailerClassQuery, RetailersRetailerClassQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RetailersRetailerClassQuery, RetailersRetailerClassQueryVariables>(RetailersRetailerClassDocument, options);
        }
export type RetailersRetailerClassQueryHookResult = ReturnType<typeof useRetailersRetailerClassQuery>;
export type RetailersRetailerClassLazyQueryHookResult = ReturnType<typeof useRetailersRetailerClassLazyQuery>;
export type RetailersRetailerClassQueryResult = Apollo.QueryResult<RetailersRetailerClassQuery, RetailersRetailerClassQueryVariables>;