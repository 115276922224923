import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type CreateClassRepresentativeMutationVariables = Types.Exact<{
  representativeId: Types.Scalars['Int'];
  retailerClassId: Types.Scalars['Int'];
}>;


export type CreateClassRepresentativeMutation = { __typename?: 'Mutation', retailersCreateRetailerClassRetailerClassRepresentative?: { __typename?: 'retailersRetailerClassRetailerClassRepresentative', id: string, representative_id: number, retailer_class_id: number } | null | undefined };


export const CreateClassRepresentativeDocument = gql`
    mutation CreateClassRepresentative($representativeId: Int!, $retailerClassId: Int!) {
  retailersCreateRetailerClassRetailerClassRepresentative(
    representative_id: $representativeId
    retailer_class_id: $retailerClassId
  ) {
    id
    representative_id
    retailer_class_id
  }
}
    `;
export type CreateClassRepresentativeMutationFn = Apollo.MutationFunction<CreateClassRepresentativeMutation, CreateClassRepresentativeMutationVariables>;

/**
 * __useCreateClassRepresentativeMutation__
 *
 * To run a mutation, you first call `useCreateClassRepresentativeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClassRepresentativeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClassRepresentativeMutation, { data, loading, error }] = useCreateClassRepresentativeMutation({
 *   variables: {
 *      representativeId: // value for 'representativeId'
 *      retailerClassId: // value for 'retailerClassId'
 *   },
 * });
 */
export function useCreateClassRepresentativeMutation(baseOptions?: Apollo.MutationHookOptions<CreateClassRepresentativeMutation, CreateClassRepresentativeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateClassRepresentativeMutation, CreateClassRepresentativeMutationVariables>(CreateClassRepresentativeDocument, options);
      }
export type CreateClassRepresentativeMutationHookResult = ReturnType<typeof useCreateClassRepresentativeMutation>;
export type CreateClassRepresentativeMutationResult = Apollo.MutationResult<CreateClassRepresentativeMutation>;
export type CreateClassRepresentativeMutationOptions = Apollo.BaseMutationOptions<CreateClassRepresentativeMutation, CreateClassRepresentativeMutationVariables>;