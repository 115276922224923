import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type DuplicatePresetMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  internal_name: Types.Scalars['String'];
  public_name: Types.Scalars['String'];
}>;


export type DuplicatePresetMutation = { __typename?: 'Mutation', duplicatePreset?: { __typename?: 'configuratorPreset', id: string } | null | undefined };


export const DuplicatePresetDocument = gql`
    mutation DuplicatePreset($id: ID!, $internal_name: String!, $public_name: String!) {
  duplicatePreset: configuratorDuplicatePreset(
    id: $id
    internal_name: $internal_name
    public_name: $public_name
  ) {
    id
  }
}
    `;
export type DuplicatePresetMutationFn = Apollo.MutationFunction<DuplicatePresetMutation, DuplicatePresetMutationVariables>;

/**
 * __useDuplicatePresetMutation__
 *
 * To run a mutation, you first call `useDuplicatePresetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicatePresetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicatePresetMutation, { data, loading, error }] = useDuplicatePresetMutation({
 *   variables: {
 *      id: // value for 'id'
 *      internal_name: // value for 'internal_name'
 *      public_name: // value for 'public_name'
 *   },
 * });
 */
export function useDuplicatePresetMutation(baseOptions?: Apollo.MutationHookOptions<DuplicatePresetMutation, DuplicatePresetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DuplicatePresetMutation, DuplicatePresetMutationVariables>(DuplicatePresetDocument, options);
      }
export type DuplicatePresetMutationHookResult = ReturnType<typeof useDuplicatePresetMutation>;
export type DuplicatePresetMutationResult = Apollo.MutationResult<DuplicatePresetMutation>;
export type DuplicatePresetMutationOptions = Apollo.BaseMutationOptions<DuplicatePresetMutation, DuplicatePresetMutationVariables>;