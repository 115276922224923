import DateRangeIcon from '@mui/icons-material/DateRange'
import RetailerYearList from './RetailerYearList'
import RetailerYearEdit from './RetailerYearEdit'
import RetailerYearCreate from './RetailerYearCreate'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    list: RetailerYearList,
    edit: RetailerYearEdit,
    create: RetailerYearCreate,
    icon: DateRangeIcon
}
