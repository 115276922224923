import { useRetailersCountriesQuery } from 'apollo/retailer_manager/queries/RetailersCountries.generated'
import { SelectInput } from 'react-admin'
import { ChoiceType } from 'types'

const CountriesSelect = (props) => {
    const { data: { retailersCountries: countries } = {} } = useRetailersCountriesQuery()
    const mappedCountries =
        countries &&
        countries
            .map(
                (item): ChoiceType => ({
                    id: item?.two_letter_abbreviation ?? '',
                    name: item?.full_name_english ?? ''
                })
            )
            .sort((a: ChoiceType, b: ChoiceType) => {
                if (a.name < b.name) return -1
                if (a.name > b.name) return 1
                return 0
            })

    return (
        <SelectInput
            {...props}
            source="country"
            variant="outlined"
            choices={(mappedCountries && mappedCountries) ?? []}
        />
    )
}

export default CountriesSelect
