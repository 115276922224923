import { List, Datagrid, TextField, ListProps } from 'react-admin'
import { useTranslate } from 'ra-core'
import datagridStyles from '../../layout/styles/datagridStyles'
import RetailerTagListFilters from './RetailerTagListFilters'
import BulkActionButtons from 'components/common/BulkActionButtons'
import { CustomDatagridBody } from 'components/common/CustomDatagridBody'
import { DEFAULT_PER_PAGE } from 'globals'
import { formatDateString } from 'utils/formatDateString'
import DateFieldCustom from 'utils/overrides/DateFieldCustom'

const RetailerTagList = (props: ListProps) => {
    const datagridClasses = datagridStyles()
    const translate = useTranslate()

    return (
        <List
            {...props}
            sort={{ field: 'tag', order: 'DESC' }}
            perPage={DEFAULT_PER_PAGE}
            filters={RetailerTagListFilters}
            bulkActionButtons={<BulkActionButtons />}
        >
            <Datagrid body={<CustomDatagridBody />} classes={datagridClasses} rowClick="edit">
                <TextField source="tag" label={translate('manager.resources.tag.name')} />
                <DateFieldCustom
                    label={translate('manager.resources.general.last_updated')}
                    source="updated_at"
                    showTime
                    transform={(value) => formatDateString(value, true)}
                />
            </Datagrid>
        </List>
    )
}
export default RetailerTagList
