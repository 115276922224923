import RetailerClassRepresentativeCreate from './RetailerClassRepresentativeCreate'
import RetailerClassRepresentativeEdit from './RetailerClassRepresentativeEdit'
import RetailerClassRepresentativeList from './RetailerClassRepresentativeList'
import AccountBoxIcon from '@mui/icons-material/AccountBox'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    list: RetailerClassRepresentativeList,
    create: RetailerClassRepresentativeCreate,
    edit: RetailerClassRepresentativeEdit,
    icon: AccountBoxIcon
}
