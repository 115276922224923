import {
    TextInput,
    useTranslate,
    required,
    TabbedForm,
    FormTab,
    number,
    minValue,
    maxValue,
    regex,
    NumberInput
} from 'react-admin'
import { FormSpy } from 'react-final-form'
import { Grid, Typography } from '@mui/material'

import DefaultToolbar from '../../components/common/DefaultToolbar'
import RetailerClassList from './RetailerClassTable/RetailerClassList'

const RetailerClassRepresentativeForm = ({
    isEdit = false,
    isPristineForm,
    setIsPristineFormCallback,
    ...props
}) => {
    const translate = useTranslate()

    const validateRegex = regex(/^[\w-]+$/, translate('manager.regex.az09'))

    return (
        <TabbedForm {...props} toolbar={<DefaultToolbar />} redirect={props.basePath}>
            <FormTab label={translate('manager.tabs.general')} path="/">
                <Typography variant="h6" gutterBottom>
                    {translate('manager.resources.contactPersons.title')}
                </Typography>
                <Grid container spacing={1}>
                    <Grid item md={7}>
                        <TextInput
                            fullWidth
                            source="name"
                            variant="outlined"
                            label={translate('manager.resources.contactPersons.name')}
                            validate={requiredValidate}
                        />
                        <TextInput
                            fullWidth
                            source="email"
                            variant="outlined"
                            label={translate('manager.resources.contactPersons.email')}
                            validate={requiredValidate}
                            type="email"
                        />
                        <TextInput
                            fullWidth
                            source="telephone"
                            variant="outlined"
                            label={translate('manager.resources.contactPersons.phone')}
                            validate={requiredValidate}
                            type="tel"
                        />
                        <TextInput
                            fullWidth
                            source="ssn"
                            variant="outlined"
                            label={translate('manager.resources.contactPersons.ssn')}
                            validate={validateRegex}
                            helperText={translate('manager.regex.az09')}
                        />
                    </Grid>
                    <Grid item md={1} />
                    <Grid item md={4}>
                        <NumberInput
                            fullWidth
                            source="discount_amount"
                            variant="outlined"
                            label={translate('manager.resources.contactPersons.discount_amount')}
                        />
                        <NumberInput
                            fullWidth
                            source="discount_percentage"
                            variant="outlined"
                            label={translate(
                                'manager.resources.contactPersons.discount_percentage'
                            )}
                            min={0}
                            max={100}
                            validate={[number(), minValue(0), maxValue(100)]}
                        />
                    </Grid>
                </Grid>
                {isEdit && props?.record?.id && <RetailerClassList />}
            </FormTab>
            <FormSpy
                subscription={{ pristine: true }}
                onChange={(props) => {
                    setIsPristineFormCallback(props?.pristine)
                }}
            />
        </TabbedForm>
    )
}

const requiredValidate = [required()]

export default RetailerClassRepresentativeForm
