import { AppState } from '@auth0/auth0-react'
import { useSelector } from 'react-redux'

interface LogoProps {
    className?: string
}

const Logo = (props: LogoProps) => {
    const theme = useSelector((state: AppState) => state.theme)
    const src = '/proud-nerds-logo.png'
    const alt = 'proud-nerds-logo'
    const height = theme === 'proudNerds' ? '50' : '32'
    return <img {...props} src={src} alt={alt} height={height} />
}

export default Logo
