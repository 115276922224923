import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type DeleteRetailerRepresentativeMutationVariables = Types.Exact<{
  representativeId: Types.Scalars['Int'];
  retailerId: Types.Scalars['Int'];
}>;


export type DeleteRetailerRepresentativeMutation = { __typename?: 'Mutation', retailersDeleteRetailerRetailerClassRepresentative?: { __typename?: 'retailersRetailerRetailerClassRepresentative', id: string, representative_id: number, retailer_id: number } | null | undefined };


export const DeleteRetailerRepresentativeDocument = gql`
    mutation DeleteRetailerRepresentative($representativeId: Int!, $retailerId: Int!) {
  retailersDeleteRetailerRetailerClassRepresentative(
    representative_id: $representativeId
    retailer_id: $retailerId
  ) {
    id
    representative_id
    retailer_id
  }
}
    `;
export type DeleteRetailerRepresentativeMutationFn = Apollo.MutationFunction<DeleteRetailerRepresentativeMutation, DeleteRetailerRepresentativeMutationVariables>;

/**
 * __useDeleteRetailerRepresentativeMutation__
 *
 * To run a mutation, you first call `useDeleteRetailerRepresentativeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRetailerRepresentativeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRetailerRepresentativeMutation, { data, loading, error }] = useDeleteRetailerRepresentativeMutation({
 *   variables: {
 *      representativeId: // value for 'representativeId'
 *      retailerId: // value for 'retailerId'
 *   },
 * });
 */
export function useDeleteRetailerRepresentativeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRetailerRepresentativeMutation, DeleteRetailerRepresentativeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRetailerRepresentativeMutation, DeleteRetailerRepresentativeMutationVariables>(DeleteRetailerRepresentativeDocument, options);
      }
export type DeleteRetailerRepresentativeMutationHookResult = ReturnType<typeof useDeleteRetailerRepresentativeMutation>;
export type DeleteRetailerRepresentativeMutationResult = Apollo.MutationResult<DeleteRetailerRepresentativeMutation>;
export type DeleteRetailerRepresentativeMutationOptions = Apollo.BaseMutationOptions<DeleteRetailerRepresentativeMutation, DeleteRetailerRepresentativeMutationVariables>;