import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type RetailersCountriesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type RetailersCountriesQuery = { __typename?: 'Query', retailersCountries?: Array<{ __typename?: 'retailersCountry', full_name_english?: string | null | undefined, full_name_locale?: string | null | undefined, three_letter_abbreviation?: string | null | undefined, two_letter_abbreviation?: string | null | undefined } | null | undefined> | null | undefined };


export const RetailersCountriesDocument = gql`
    query RetailersCountries {
  retailersCountries {
    full_name_english
    full_name_locale
    three_letter_abbreviation
    two_letter_abbreviation
  }
}
    `;

/**
 * __useRetailersCountriesQuery__
 *
 * To run a query within a React component, call `useRetailersCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetailersCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetailersCountriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useRetailersCountriesQuery(baseOptions?: Apollo.QueryHookOptions<RetailersCountriesQuery, RetailersCountriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RetailersCountriesQuery, RetailersCountriesQueryVariables>(RetailersCountriesDocument, options);
      }
export function useRetailersCountriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RetailersCountriesQuery, RetailersCountriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RetailersCountriesQuery, RetailersCountriesQueryVariables>(RetailersCountriesDocument, options);
        }
export type RetailersCountriesQueryHookResult = ReturnType<typeof useRetailersCountriesQuery>;
export type RetailersCountriesLazyQueryHookResult = ReturnType<typeof useRetailersCountriesLazyQuery>;
export type RetailersCountriesQueryResult = Apollo.QueryResult<RetailersCountriesQuery, RetailersCountriesQueryVariables>;