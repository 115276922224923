import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type CreateRetailerRepresentativeMutationVariables = Types.Exact<{
  representativeId: Types.Scalars['Int'];
  retailerId: Types.Scalars['Int'];
}>;


export type CreateRetailerRepresentativeMutation = { __typename?: 'Mutation', retailersCreateRetailerRetailerClassRepresentative?: { __typename?: 'retailersRetailerRetailerClassRepresentative', id: string, representative_id: number, retailer_id: number } | null | undefined };


export const CreateRetailerRepresentativeDocument = gql`
    mutation CreateRetailerRepresentative($representativeId: Int!, $retailerId: Int!) {
  retailersCreateRetailerRetailerClassRepresentative(
    representative_id: $representativeId
    retailer_id: $retailerId
  ) {
    id
    representative_id
    retailer_id
  }
}
    `;
export type CreateRetailerRepresentativeMutationFn = Apollo.MutationFunction<CreateRetailerRepresentativeMutation, CreateRetailerRepresentativeMutationVariables>;

/**
 * __useCreateRetailerRepresentativeMutation__
 *
 * To run a mutation, you first call `useCreateRetailerRepresentativeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRetailerRepresentativeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRetailerRepresentativeMutation, { data, loading, error }] = useCreateRetailerRepresentativeMutation({
 *   variables: {
 *      representativeId: // value for 'representativeId'
 *      retailerId: // value for 'retailerId'
 *   },
 * });
 */
export function useCreateRetailerRepresentativeMutation(baseOptions?: Apollo.MutationHookOptions<CreateRetailerRepresentativeMutation, CreateRetailerRepresentativeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRetailerRepresentativeMutation, CreateRetailerRepresentativeMutationVariables>(CreateRetailerRepresentativeDocument, options);
      }
export type CreateRetailerRepresentativeMutationHookResult = ReturnType<typeof useCreateRetailerRepresentativeMutation>;
export type CreateRetailerRepresentativeMutationResult = Apollo.MutationResult<CreateRetailerRepresentativeMutation>;
export type CreateRetailerRepresentativeMutationOptions = Apollo.BaseMutationOptions<CreateRetailerRepresentativeMutation, CreateRetailerRepresentativeMutationVariables>;