import { useTranslate } from 'ra-core'
import { useState } from 'react'
import { ChoiceType } from 'types'
import { useCreateRetailerTagMutation } from 'apollo/retailer_manager/mutations/CreateRetailerTag.generated'
import { useRetailersRetailerClassQuery } from 'apollo/retailer_manager/queries/RetailersRetailerClass.generated'
import { useRetailersRetailerTagsQuery } from 'apollo/retailer_manager/queries/RetailersRetailerTags.generated'
import TagsAutocompleteInput from 'components/formfields/TagsAutocompleteInput'

/*
 * Wrapper component to provide the actual autocomplete input with data and functionality \
 */
const RetailerClassTagsInput = ({ retailerClassId }) => {
    const translate = useTranslate()
    const [initialValues, setInitialValues] = useState<ChoiceType[]>([])
    const [initialChoices, setInitialChoices] = useState<ChoiceType[]>([])
    const [createRetailerTag] = useCreateRetailerTagMutation()

    useRetailersRetailerClassQuery({
        variables: {
            id: retailerClassId
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-and-network',
        onCompleted: ({ retailersRetailerClass }) => {
            if (retailersRetailerClass) {
                setInitialValues(
                    retailersRetailerClass.tags.map((tag) => ({
                        id: tag.id,
                        name: tag.tag
                    }))
                )
            }
        }
    })

    // Retrieve every possible tag to populate choices in the autocomplete
    useRetailersRetailerTagsQuery({
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-and-network',
        onCompleted: ({ retailersRetailerTags }) => {
            if (retailersRetailerTags) {
                setInitialChoices(
                    retailersRetailerTags.data.map((tag) => ({
                        id: tag.id,
                        name: tag.tag
                    }))
                )
            }
        }
    })

    // Prompt user for new tag
    const handleCreate = async () => {
        const newTag = prompt(translate('manager.resources.retailer.enter_new_tag'))
        if (!newTag) return

        const found = initialChoices.find((item) => item.name === newTag)
        if (found) return found

        // Save and return new tag
        const tag = await save(newTag)
        initialChoices && tag && initialChoices.push(tag)
        return tag
    }

    // Save new tag in db
    const save = async (newTag: string) => {
        return await createRetailerTag({
            variables: { tag: newTag }
        })
            .then(async ({ data }) => {
                return {
                    id: data?.createRetailerTag?.id ?? '',
                    name: data?.createRetailerTag?.tag ?? ''
                }
            })
            .catch((error) => console.error('createRetailerClassTag:', error))
    }

    return (
        <TagsAutocompleteInput
            source="tags"
            initialValues={initialValues}
            initialChoices={initialChoices}
            onCreate={handleCreate}
        />
    )
}
export default RetailerClassTagsInput
