import { useState } from 'react'
import { Edit, useTranslate } from 'react-admin'

import { BackFromEditorButton } from 'components/common/BackFromEditorButton'

import RetailerForm from './RetailerForm'

const RetailerEdit = (props) => {
    const translate = useTranslate()
    const [isPristineForm, setIsPristineForm] = useState(true)

    return (
        <>
            <BackFromEditorButton isPristineForm={isPristineForm} backToRouteURL="/retailer" />
            <Edit title={translate('manager.resources.retailer.edit')} {...props}>
                <RetailerForm
                    isEdit
                    isPristineForm={isPristineForm}
                    setIsPristineFormCallback={setIsPristineForm}
                    {...props}
                />
            </Edit>
        </>
    )
}

export default RetailerEdit
