export enum CRUD {
    Create,
    Read,
    Update,
    Delete
}

export interface RoleDefinitionInterface {
    [key: string]: ResourceInterface
}

export interface ResourceInterface {
    [key: string]: CRUD[]
}

export const roleDefinition: RoleDefinitionInterface = {
    Admin: {
        Education: [CRUD.Create, CRUD.Read, CRUD.Update, CRUD.Delete],
        Package: [CRUD.Create, CRUD.Read, CRUD.Update, CRUD.Delete],
        PackageGalleryItem: [CRUD.Create, CRUD.Read, CRUD.Update, CRUD.Delete],
        PackageGroupCollection: [CRUD.Create, CRUD.Read, CRUD.Update, CRUD.Delete],
        PackageProduct: [CRUD.Create, CRUD.Read, CRUD.Update, CRUD.Delete],
        PackageGroup: [CRUD.Create, CRUD.Read, CRUD.Update, CRUD.Delete],
        PackageGroupCollectionGalleryItem: [CRUD.Create, CRUD.Read, CRUD.Update, CRUD.Delete],
        PackageGroupGalleryItem: [CRUD.Create, CRUD.Read, CRUD.Update, CRUD.Delete],
        Rule: [CRUD.Create, CRUD.Read, CRUD.Update, CRUD.Delete],
        Preset: [CRUD.Create, CRUD.Read, CRUD.Update, CRUD.Delete],
        Retailer: [CRUD.Create, CRUD.Read, CRUD.Update, CRUD.Delete],
        RetailerClass: [CRUD.Create, CRUD.Read, CRUD.Update, CRUD.Delete],
        RetailerClassRepresentative: [CRUD.Create, CRUD.Read, CRUD.Update, CRUD.Delete],
        RetailerPeriod: [CRUD.Create, CRUD.Read, CRUD.Update, CRUD.Delete],
        RetailerTag: [CRUD.Create, CRUD.Read, CRUD.Update, CRUD.Delete],
        RetailerYear: [CRUD.Create, CRUD.Read, CRUD.Update, CRUD.Delete],
        Model: [CRUD.Create, CRUD.Read, CRUD.Update, CRUD.Delete],
        Gallery: [CRUD.Create, CRUD.Read, CRUD.Update, CRUD.Delete]
    },
    Superuser: {
        Education: [CRUD.Create, CRUD.Read, CRUD.Update, CRUD.Delete],
        Rule: [CRUD.Create, CRUD.Read, CRUD.Update, CRUD.Delete],
        Preset: [CRUD.Create, CRUD.Read, CRUD.Update, CRUD.Delete],
        Retailer: [CRUD.Create, CRUD.Read, CRUD.Update, CRUD.Delete],
        RetailerClass: [CRUD.Create, CRUD.Read, CRUD.Update, CRUD.Delete],
        RetailerClassRepresentative: [CRUD.Create, CRUD.Read, CRUD.Update, CRUD.Delete],
        RetailerPeriod: [CRUD.Create, CRUD.Read, CRUD.Update, CRUD.Delete],
        RetailerTag: [CRUD.Create, CRUD.Read, CRUD.Update, CRUD.Delete],
        RetailerYear: [CRUD.Create, CRUD.Read, CRUD.Update, CRUD.Delete],
        Gallery: [CRUD.Create, CRUD.Read, CRUD.Update, CRUD.Delete]
    },
    EventTeam: {
        Education: [CRUD.Create, CRUD.Read, CRUD.Update, CRUD.Delete],
        Retailer: [CRUD.Create, CRUD.Read, CRUD.Update, CRUD.Delete],
        RetailerClass: [CRUD.Create, CRUD.Read, CRUD.Update, CRUD.Delete],
        RetailerClassRepresentative: [CRUD.Create, CRUD.Read, CRUD.Update, CRUD.Delete],
        RetailerPeriod: [CRUD.Create, CRUD.Read, CRUD.Update, CRUD.Delete],
        RetailerTag: [CRUD.Create, CRUD.Read, CRUD.Update, CRUD.Delete],
        RetailerYear: [CRUD.Create, CRUD.Read, CRUD.Update, CRUD.Delete]
    },
    Basic: {
        Rule: [CRUD.Create, CRUD.Read, CRUD.Update, CRUD.Delete],
        Preset: [CRUD.Create, CRUD.Read, CRUD.Update, CRUD.Delete],
        Model: [CRUD.Create, CRUD.Read, CRUD.Update, CRUD.Delete],
        Gallery: [CRUD.Create, CRUD.Read, CRUD.Update, CRUD.Delete]
    }
}
