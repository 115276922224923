import { useState } from 'react'
import {
    useTranslate,
    ArrayField,
    Datagrid,
    TextField,
    TopToolbar,
    useRefresh,
    FunctionField
} from 'react-admin'
import { Record } from 'ra-core'
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Tooltip,
    Typography
} from '@material-ui/core'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import { makeStyles } from '@material-ui/core/styles'
import _ from 'lodash'

import { formStyles } from 'layout/styles/formStyles'
import AutoCompleteSelectWithData from 'components/formfields/AutoCompleteSelectWithData'

import { useRetailersRetailersQuery } from 'apollo/retailer_manager/queries/RetailersRetailers.generated'
import { useCreateRetailerRepresentativeMutation } from 'apollo/contact_person_manager/mutations/CreateRetailerRepresentative.generated'
import { useDeleteRetailerRepresentativeMutation } from 'apollo/contact_person_manager/mutations/DeleteRetailerRepresentative.generated'
import { useCreateClassRepresentativeMutation } from 'apollo/contact_person_manager/mutations/CreateClassRepresentative.generated'
import { useDeleteClassRepresentativeMutation } from 'apollo/contact_person_manager/mutations/DeleteClassRepresentative.generated'
import { useRetailersRetailerClassesQuery } from 'apollo/contact_person_manager/queries/RetailersRetailerClasses.generated'

const useStyles = makeStyles(formStyles)

const useCustomStyles = makeStyles({
    dialogActions: {
        padding: 0,
        paddingBottom: '1rem'
    },
    deleteIcon: {
        cursor: 'pointer',
        '&:hover': {
            transform: `scale(1.1)`
        }
    },
    spinner: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: '-12px',
        marginLeft: '-12px'
    },
    datagridLoading: {
        opacity: 0.5,
        cursor: 'progress',
        pointerEvents: 'none'
    }
})

const RetailerClassList = (props) => {
    const translate = useTranslate()
    const formStyle = useStyles()
    const customStyles = useCustomStyles()
    const reload = useRefresh()

    const [open, setOpen] = useState(false)
    const [selectedRetailerID, setSelectedRetailerID] = useState<number | null>(null)
    const [selectedClassID, setSelectedClassID] = useState<number | null>(null)

    const [
        createRetailerRepresentativeMutation,
        { loading: isLoadingCreateRetailerRepresentativeMutation }
    ] = useCreateRetailerRepresentativeMutation()
    const [
        deleteRetailerRepresentativeMutation,
        { loading: isLoadingDeleteRetailerRepresentativeMutation }
    ] = useDeleteRetailerRepresentativeMutation()

    const [createClassRepresentativeMutation] = useCreateClassRepresentativeMutation()
    const [
        deleteClassRepresentativeMutation,
        { loading: isLoadingDeleteClassRepresentativeMutation }
    ] = useDeleteClassRepresentativeMutation()

    const togglePopup = (): void => {
        setOpen(!open)
    }

    const handleRetailerAutocompleteChange = ({ id }) => {
        setSelectedRetailerID(+id)
    }

    const handleClassAutocompleteChange = ({ id }) => {
        setSelectedClassID(+id)
    }

    const handlePopupSubmit = () => {
        if (selectedRetailerID && !selectedClassID)
            createRetailerRepresentativeMutation({
                variables: {
                    representativeId: +props?.record?.id,
                    retailerId: selectedRetailerID
                }
            })
                .then(() => {
                    reload()
                })
                .catch((error) => {
                    console.error(error)
                })

        if (selectedClassID) {
            createClassRepresentativeMutation({
                variables: {
                    representativeId: +props?.record?.id,
                    retailerClassId: selectedClassID
                }
            })
                .then(() => {
                    reload()
                })
                .catch((error) => {
                    console.error(error)
                })
        }
    }

    const handleRetailerDeleteClick = (event, id) => {
        deleteRetailerRepresentativeMutation({
            variables: {
                representativeId: +props?.record?.id,
                retailerId: id
            }
        })
            .then(() => {
                reload()
            })
            .catch((error) => {
                console.error(error)
            })
    }

    const handleClassDeleteClick = (event, id) => {
        deleteClassRepresentativeMutation({
            variables: {
                representativeId: +props?.record?.id,
                retailerClassId: id
            }
        })
            .then(() => {
                reload()
            })
            .catch((error) => {
                console.error(error)
            })
    }

    const { data: retailers = {} } = useRetailersRetailersQuery({
        variables: {
            first: 10000
        },
        fetchPolicy: 'cache-and-network'
    })

    const filteredRetailers = _.differenceBy(
        retailers?.retailersRetailers?.data,
        props?.record?.retailers,
        'id'
    )

    const { data: classes = {} } = useRetailersRetailerClassesQuery({
        variables: {
            filter: {
                retailer_id: selectedRetailerID
            }
        },
        fetchPolicy: 'cache-and-network'
    })

    const filteredClasses = _.differenceBy(
        classes?.retailersRetailerClasses?.data,
        props?.record?.classes,
        'id'
    )

    return (
        <>
            <TopToolbar>
                <Button color="primary" variant="outlined" onClick={togglePopup}>
                    <AddIcon />
                    {translate(`manager.resources.contactPersons.add_retailer_class`)}
                </Button>
            </TopToolbar>

            <Grid
                container
                spacing={1}
                className={formStyle.fullWidth}
                style={{ marginBottom: 30 }}
            >
                <Grid item md={6} style={{ position: 'relative' }}>
                    <ArrayField source="retailers">
                        <Datagrid
                            className={
                                isLoadingDeleteRetailerRepresentativeMutation
                                    ? customStyles.datagridLoading
                                    : ''
                            }
                        >
                            <TextField
                                source="name"
                                label={translate('manager.resources.contactPersons.retailer_name')}
                            />
                            <FunctionField
                                label={translate('manager.resources.general.actions')}
                                render={(record?: Record | undefined) =>
                                    record?.id && (
                                        <Tooltip
                                            title={translate('manager.resources.general.delete')}
                                        >
                                            <DeleteIcon
                                                className={customStyles.deleteIcon}
                                                onClick={(event) =>
                                                    handleRetailerDeleteClick(event, +record?.id)
                                                }
                                            />
                                        </Tooltip>
                                    )
                                }
                            />
                        </Datagrid>
                    </ArrayField>
                    {isLoadingDeleteRetailerRepresentativeMutation && (
                        <CircularProgress size={24} className={customStyles.spinner} />
                    )}
                </Grid>
                <Grid item md={6} style={{ position: 'relative' }}>
                    <ArrayField source="classes">
                        <Datagrid
                            className={
                                isLoadingDeleteClassRepresentativeMutation
                                    ? customStyles.datagridLoading
                                    : ''
                            }
                        >
                            <TextField
                                source="name"
                                label={translate('manager.resources.contactPersons.class_name')}
                            />
                            <FunctionField
                                label={translate('manager.resources.general.actions')}
                                render={(record?: Record | undefined) =>
                                    record?.id && (
                                        <Tooltip
                                            title={translate('manager.resources.general.delete')}
                                        >
                                            <DeleteIcon
                                                className={customStyles.deleteIcon}
                                                onClick={(event) =>
                                                    handleClassDeleteClick(event, +record?.id)
                                                }
                                            />
                                        </Tooltip>
                                    )
                                }
                            />
                        </Datagrid>
                    </ArrayField>
                    {isLoadingDeleteClassRepresentativeMutation && (
                        <CircularProgress size={24} className={customStyles.spinner} />
                    )}
                </Grid>
            </Grid>

            <Dialog fullWidth open={open} onClose={togglePopup}>
                <DialogTitle>
                    <Typography variant="h6" gutterBottom>
                        {translate('manager.resources.contactPersons.add_retailer_class')}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <AutoCompleteSelectWithData
                        required
                        format
                        source="retailers"
                        data={filteredRetailers ?? []}
                        onSelect={handleRetailerAutocompleteChange}
                        disabled={filteredRetailers.length === 0}
                    />
                    <AutoCompleteSelectWithData
                        format
                        source="classes"
                        data={filteredClasses ?? []}
                        onSelect={handleClassAutocompleteChange}
                        disabled={!filteredClasses || filteredClasses?.length === 0}
                    />
                    <DialogActions className={customStyles.dialogActions}>
                        <Button onClick={togglePopup} variant="contained" color="default">
                            {translate('manager.resources.packageGroup.package.close')}
                        </Button>
                        <Button
                            onClick={handlePopupSubmit}
                            variant="contained"
                            color="primary"
                            disabled={
                                !selectedRetailerID || isLoadingCreateRetailerRepresentativeMutation
                            }
                        >
                            {translate('manager.resources.packageGroup.package.add_button')}
                            {isLoadingCreateRetailerRepresentativeMutation && (
                                <CircularProgress size={24} className={customStyles.spinner} />
                            )}
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default RetailerClassList
