// import { startOfDay, endOfDay, subDays, subMonths, subYears } from 'date-fns'
import { BooleanInput, SearchInput /* , useInput */ } from 'react-admin'
// import { useTranslate } from 'ra-core'
// import DateRangePicker, { RangeType } from 'rsuite/DateRangePicker'
import 'rsuite/dist/rsuite-no-reset.min.css'

/* const Ranges: RangeType[] = [
    {
        label: 'Last week',
        value: [startOfDay(subDays(new Date(), 6)), endOfDay(new Date())]
    },
    {
        label: 'Last month',
        value: [startOfDay(subMonths(new Date(), 1)), endOfDay(new Date())]
    },
    {
        label: 'Last 3 months',
        value: [startOfDay(subMonths(new Date(), 3)), endOfDay(new Date())]
    },
    {
        label: 'Last 6 months',
        value: [startOfDay(subMonths(new Date(), 6)), endOfDay(new Date())]
    },
    {
        label: 'Last year',
        value: [startOfDay(subYears(new Date(), 1)), endOfDay(new Date())]
    }
] */

/* const DateRangeInput = ({ source, label }) => {
    const translate = useTranslate()
    const input = useInput({ source })

    return (
        <DateRangePicker
            value={
                input.input.value
                    ? [
                          new Date(input.input.value?.split('|')?.[0]),
                          new Date(input.input.value?.split('|')?.[1])
                      ]
                    : null
            }
            onChange={(value) => {
                input.input.onChange(
                    `${value?.[0].toISOString().split('T')[0]}|${
                        value?.[1].toISOString().split('T')[0]
                    }`
                )
            }}
            ranges={Ranges}
            cleanable={false}
            placeholder={translate('manager.resources.retailer.filter_active_periods')}
        />
    )
} */

const RetailerListFilters = [
    <SearchInput variant="outlined" placeholder="Search name" source="name" alwaysOn />,
    // <SearchInput variant="outlined" placeholder="Search Code" source="code" alwaysOn />,
    <SearchInput variant="outlined" placeholder="Search Country" source="country" />,
    // <DateRangeInput source="period" label="Active periods" />,
    <BooleanInput source="active" label="Published" variant="outlined" defaultValue={true} />
]

export default RetailerListFilters
