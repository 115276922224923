import { List, Datagrid, TextField, ListProps, FunctionField, Pagination } from 'react-admin'
import { Record, useTranslate } from 'ra-core'

import datagridStyles from '../../layout/styles/datagridStyles'
import PackageGroupCollectionListFilter from './PackageGroupCollectionListFilters'
import DatagridPublishedColumnContent from '../../components/common/DatagridPublishedColumnContent'
import BulkActionButtons from 'components/common/BulkActionButtons'
import { CustomDatagridBody } from 'components/common/CustomDatagridBody'
import { DEFAULT_PER_PAGE, ROWS_PER_PAGE } from '../../globals'
import { makeStyles } from '@material-ui/core/styles'
import { formatDateString } from 'utils/formatDateString'
import DateFieldCustom from 'utils/overrides/DateFieldCustom'

const useStyles = makeStyles({
    retailersList: {
        '& .MuiToolbar-gutters': { width: 'auto' }
    }
})

const PackageGroupCollectionList = (props: ListProps) => {
    const datagridClasses = datagridStyles()
    const translate = useTranslate()
    const classes = useStyles()

    return (
        <List
            {...props}
            sort={{ field: 'internal_name', order: 'DESC' }}
            empty={false}
            perPage={DEFAULT_PER_PAGE}
            bulkActionButtons={<BulkActionButtons isWithActionsFlush />}
            filters={PackageGroupCollectionListFilter}
            className={classes.retailersList}
            pagination={<Pagination rowsPerPageOptions={ROWS_PER_PAGE} />}
        >
            <Datagrid body={<CustomDatagridBody />} classes={datagridClasses} rowClick="edit">
                <TextField
                    source="internal_name"
                    label={translate('manager.resources.packageGroupCollection.internal_name')}
                />
                <TextField
                    source="public_name"
                    label={translate('manager.resources.packageGroupCollection.public_name')}
                />
                <FunctionField
                    source="active"
                    label={translate('manager.resources.general.status')}
                    render={(record?: Record | undefined) => (
                        <DatagridPublishedColumnContent record={record} />
                    )}
                />
                <DateFieldCustom
                    label={translate('manager.resources.general.last_updated')}
                    source="updated_at"
                    showTime
                    transform={(value) => formatDateString(value, true)}
                />
            </Datagrid>
        </List>
    )
}
export default PackageGroupCollectionList
