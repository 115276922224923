import {
    List,
    Datagrid,
    TextField,
    ListProps,
    FunctionField,
    Pagination,
    useRedirect,
    useNotify
} from 'react-admin'
import { Record, useTranslate } from 'ra-core'
import datagridStyles from '../../layout/styles/datagridStyles'
import PresetListFilters from './PresetListFilters'
import DatagridPublishedColumnContent from '../../components/common/DatagridPublishedColumnContent'
import { CustomDatagridBody } from 'components/common/CustomDatagridBody'
import PresetBulkActionButtons from './PresetBulkActionButtons'
import { DEFAULT_PER_PAGE, ROWS_PER_PAGE } from '../../globals'
import { formatDateString } from 'utils/formatDateString'
import DateFieldCustom from 'utils/overrides/DateFieldCustom'
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Tooltip,
    Typography
} from '@material-ui/core'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import { ChangeEvent, MouseEvent, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import MaterialTextfield from '@material-ui/core/TextField'
import { useDuplicatePresetMutation } from '../../apollo/configurator/mutations/DuplicatePreset.generated'

const useStyles = makeStyles({
    chip: { marginRight: '1px' },
    list: {
        '& .MuiToolbar-gutters': { width: 'auto' }
    },
    listInCollection: {
        width: '100%',
        '& .MuiPaper-root': { border: 0 }
    },
    fileIcon: {
        '&:hover': {
            transform: `scale(1.1)`
        }
    },
    dialogActions: {
        padding: 0,
        paddingBottom: '1rem'
    },
    spinner: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: '-12px',
        marginLeft: '-12px'
    }
})

const PresetList = (props: ListProps) => {
    const datagridClasses = datagridStyles()
    const translate = useTranslate()
    const classes = useStyles()
    const [duplicateId, setDuplicateId] = useState('')
    const [publicName, setPublicName] = useState('')
    const [internalName, setInternalName] = useState('')
    const [open, setOpen] = useState(false)
    const [duplicatePreset, { loading: isLoadingDuplication }] = useDuplicatePresetMutation()
    const redirect = useRedirect()
    const notify = useNotify()

    const togglePopup = (): void => {
        setOpen(!open)
    }

    const handlePublicNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        setPublicName(e.target.value)
    }

    const handleInternalNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        setInternalName(e.target.value)
    }

    const handleDuplicateClick = (e: MouseEvent<SVGSVGElement>, id: string) => {
        e?.stopPropagation() // Stops rowClick from firing
        setPublicName('')
        setInternalName('')
        setDuplicateId(id)
        togglePopup()
    }

    const handlePopupSubmit = () => {
        duplicatePreset({
            variables: { id: duplicateId, internal_name: internalName, public_name: publicName }
        })
            .then((result) => {
                redirect('edit', '/Preset', result.data?.duplicatePreset?.id)
            })
            .catch((error) => notify(error.message, 'error'))
    }

    return (
        <>
            <List
                {...props}
                sort={{ field: 'internal_name', order: 'DESC' }}
                perPage={DEFAULT_PER_PAGE}
                filters={PresetListFilters}
                bulkActionButtons={<PresetBulkActionButtons listProps={props} isWithActionsFlush />}
                pagination={<Pagination rowsPerPageOptions={ROWS_PER_PAGE} />}
            >
                <Datagrid body={<CustomDatagridBody />} classes={datagridClasses} rowClick="edit">
                    <TextField
                        source="internal_name"
                        label={translate('manager.resources.preset.internal_name')}
                    />
                    <TextField
                        source="public_name"
                        label={translate('manager.resources.preset.public_name')}
                    />
                    <FunctionField
                        source="active"
                        label={translate('manager.resources.general.status')}
                        render={(record?: Record | undefined) => (
                            <DatagridPublishedColumnContent record={record} />
                        )}
                    />
                    <DateFieldCustom
                        label={translate('manager.resources.general.last_updated')}
                        source="updated_at"
                        showTime
                        transform={(value) => formatDateString(value, true)}
                    />
                    <FunctionField
                        label={translate('manager.resources.general.actions')}
                        render={(record?: Record | undefined) =>
                            record?.id && (
                                <Tooltip title={translate('manager.resources.general.duplicate')}>
                                    <FileCopyIcon
                                        className={classes.fileIcon}
                                        onClick={(e: MouseEvent<SVGSVGElement>) =>
                                            handleDuplicateClick(e, record.id.toString())
                                        }
                                    />
                                </Tooltip>
                            )
                        }
                    />
                </Datagrid>
            </List>
            <Dialog fullWidth open={open} onClose={togglePopup}>
                <DialogTitle>
                    <Typography variant="h6" gutterBottom>
                        {translate('manager.resources.preset.duplicate_preset')}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <MaterialTextfield
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        required
                        label={translate('manager.resources.preset.internal_name')}
                        onChange={handleInternalNameChange}
                    />
                    <MaterialTextfield
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        required
                        label={translate('manager.resources.preset.public_name')}
                        onChange={handlePublicNameChange}
                    />
                    <DialogActions className={classes.dialogActions}>
                        <Button onClick={togglePopup} variant="contained" color="default">
                            {translate('manager.resources.packageGroup.package.close')}
                        </Button>
                        <Button
                            onClick={handlePopupSubmit}
                            variant="contained"
                            color="primary"
                            disabled={!publicName || !internalName || isLoadingDuplication}
                        >
                            {translate('manager.resources.packageGroup.package.add_button')}
                            {isLoadingDuplication && (
                                <CircularProgress size={24} className={classes.spinner} />
                            )}
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </>
    )
}
export default PresetList
